import ClientList from "../components/home/clientlist"
import Cta from "../components/cta"
import Footer from "../components/footer"
import Header from "../components/header"
import Hero from "../components/portfolio/hero"
import Info from "../components/portfolio/portfolioInfo"
import Layout from "../components/layout"
import React from "react"
import Seo from "../components/seo2"
import { getStaticContentByIdentifier } from "../services/misc.service";
import { getSEOByIdentifier } from "../services/seo.service";

// TODO: NEED TO CHANGE SEO (desc / keywords)
const PortfolioPage = props => {
  const staticContent = props.pageContext.staticContent;
  const caseStudies = props.pageContext.caseStudies;
  const clientList = props.pageContext.clientList;
  const SEOInfo = props.pageContext.SEOInfo;

  return (
    <Layout>
      <Seo SEOInfo={getSEOByIdentifier('portfolio', SEOInfo)}/>
      <Header />
      <main id="maincontent">
        <Hero staticContent={staticContent}/>
        <Info caseStudies={caseStudies}/>
        <ClientList staticContent={staticContent} clientList={clientList}/>
        <Cta title={getStaticContentByIdentifier('portfolio.CTA.content', staticContent)} button={getStaticContentByIdentifier('portfolio.CTA.button', staticContent)}/>
      </main>
      <Footer />
    </Layout>
  )
}

export default PortfolioPage;
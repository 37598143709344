import "./hero.scss"
import { getStaticContentByIdentifier } from "../../services/misc.service";

import React from "react"

export default (props) => {
  return (
    <section className="portfolio-hero">
      <div className="contact-container-flex padding-l-2 padding-r-2">
        <div className="col-xs-12 col-md-12">
          <div className="portfolio-hero-main-heading row">
            <h1 className="text-center">{getStaticContentByIdentifier('portfolio.hero.title', props.staticContent)}</h1>
          </div>
          <div className="row">
            <div className="portfolio-hero-sub-heading text-center">{getStaticContentByIdentifier('portfolio.hero.content', props.staticContent)}</div>
          </div>
        </div>
      </div>
    </section>
  )
}
import './portfolioInfo.scss'

import PortfolioCaseStudyCard from './portfolioCaseStudyCard';
import React from 'react'
import Wave from '../wave'

const PortfolioInfo = props => {

props.caseStudies.sort((a, b) => a.positionIndex - b.positionIndex)

  const isDataAvailable = () => {
    return props.caseStudies.length >= 1;
  }
  return (
    <section className="portfolio-main-section bg-gradient">
      <div className="portfolio-row row">
          {
            isDataAvailable()
              ? (
                props.caseStudies.map((x, i) => (
                    <div key={i}><PortfolioCaseStudyCard cardInfo={x} /></div>
                ))
              )
              : (
                <div className="no-port-info-div">
                  Currently, there are no portfolio items to display.
                </div>
              )
            }
      </div>
      <div className="wave-div">
        <Wave color="#ffffff" background="#f8f8f8"/>
      </div>
    </section>
  )
}

export default PortfolioInfo;